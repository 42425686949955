import
    {
        Router,
        ActivatedRouteSnapshot,
        RouterStateSnapshot,
        CanActivate,
    } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate
{
    userProfile:any;
    constructor(private router: Router, private authenticationService: AuthenticationService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        console.log(this.authenticationService.isAuthenticated());
        
        if (this.authenticationService.isAuthenticated()) { 
            let url=state.url.split('/').splice(1, 2)
            if (url[0]=='') {
                localStorage.setItem('pageUrl','["dentalbridge","manage-api"]')
                this.authenticationService.login();
                return false; 
            }else{
                localStorage.setItem('pageUrl',JSON.stringify(url))
                return true; 
            }
        }
        if (this.authenticationService.isAuthenticated()) { return true; }
        this.authenticationService.login();
        return false;
    }
}

// const urlParams = new URLSearchParams(state.url.split('?')[1]);
//             const validate = urlParams.get('validate');
//             console.log('validate',validate);
            
//             if (validate=='false') {
//                 localStorage.setItem('validate',validate)  
//             }else{
//                 let url=state.url.split('/').splice(1, 2)    
//                 localStorage.setItem('pageUrl',JSON.stringify(url))
//             }
