import { ToastType } from './enums/toast-type';

export class Toast
{
    id: string;
    type: ToastType;
    title: string;
    message: string;
    icon: string;
    autoClose: boolean = true;
    keepAfterRouteChange: boolean = false;
    delay: 10000;
    
    constructor(init?: Partial<Toast>)
    {
        Object.assign(this, init);
    }
}

