<div class="form w-100 my-auto d-flex justify-content-center align-items-center" style="width: 100vw;height: 100vh;">
  <div>
      <div *ngIf="!error" class="text-center">
          <h2 class="fw-bold mb-3">Welcome to</h2>
          <h1 class="fw-bolder">Dentistry Automation</h1>
          <h5 class="fw-normal fs-6">Grow your practice like it's meant to be</h5>
          <!-- <h2 class="fw-bold mt-15">{{msg}}</h2> -->
          <h2 class="fw-bold">
          <!-- <ng-container *ngIf="isLoading$ | async">
              <span class="indicator-progress" [style.display]="'block'">Be patience, thanks! <span class="spinner-border spinner-border-sm align-middle ms-2"></span> -->
          </h2>
      </div>
      <div *ngIf="error" class="text-center">
          <h2 class="fw-bold mb-3">Oops, there was an error, please try to <a class="text-underline" routerLink="/login">login again</a>.</h2>
      </div>
      <ng-container >
          <span class="indicator-progress text-center" [style.display]="'block'"><span style="width: 100px;height:100px;font-size: 30px;color:#156360" class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
      </ng-container>
  </div>
</div>
