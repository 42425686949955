import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { API } from 'src/app/core/constant/api.constants';
import { ToastService } from 'src/app/shared/toast/services/toast-service';
import { UserProfile } from '../models/user.mode';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';

@Injectable({
    providedIn: 'root',
})
export class UserSetupService
{

    private unsubscribe: Subscription[] = [];

    isLoading$: Observable<boolean>;
    isLoadingSubject: BehaviorSubject<boolean>;

    user$: Observable<UserProfile>;
    userSubject: BehaviorSubject<UserProfile>;

    get userValue(): UserProfile
    {
        return this.userSubject.value;
    }

    set userValue(userProfile: UserProfile)
    {
        this.userSubject.next(userProfile);
    }

    constructor(private http: HttpClient, private toastService: ToastService)
    {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.isLoading$ = this.isLoadingSubject.asObservable();
        this.userSubject = new BehaviorSubject<UserProfile>(this.getUserFromLocalStorage());
        this.user$ = this.userSubject.asObservable();
    }

    getUserDetails(): Observable<any>
    {
        this.isLoadingSubject.next(true);
        return this.http.get<any>(API.SETUP.USER)
            .pipe(
                map((res: ApiResponse) =>
                {
                    const result = res;
                    if (res.data)
                    {
                        this.setUserFromLocalStorage(res.data);
                        this.userSubject = new BehaviorSubject<UserProfile>(res.data);
                    }
                    return result.data;
                }),
                // switchMap(() => this.getUserByToken() as any),
                catchError((err) =>
                {
                    // this.toastService.error(err);
                    return of(undefined);
                }),
                finalize(() => this.isLoadingSubject.next(false))
            );
    }


    getUserFromLocalStorage(): UserProfile
    {
        try
        {
            const authData = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER));
            return authData;
        }
        catch (error)
        {
            console.error(error);
        }
        return null;
    }

    private setUserFromLocalStorage(userProfile: UserProfile): boolean
    {
        if (userProfile)
        {
            localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(userProfile));
            return true;
        }
        return false;
    }

    ngOnDestroy()
    {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
