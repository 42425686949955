import { Component, ChangeDetectorRef, Input, OnInit, TemplateRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastType } from '../../models/enums/toast-type';
import { Toast } from '../../models/toast.model';
import { ToastService } from '../../services/toast-service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  host: { 'class': 'toast-container position-fixed top-0 end-0 p-3', 'style': 'z-index: 1200' }
})
export class ToastComponent implements OnInit
{

  @Input() id = 'default-toast';

  toasts: Toast[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;
  TempToast: Toast;

  // isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  constructor(private router: Router, public toastService: ToastService, private ChangeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void
  {
    // subscribe to new toast notifications
    this.alertSubscription = this.toastService.onToast(this.id)
      .subscribe(toast =>
      {
        // clear toasts when an empty toast is received
        if (!toast.message)
        {
          // filter out toasts without 'keepAfterRouteChange' flag
          this.toasts = this.toasts.filter(x => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          this.toasts.forEach(x => delete x.keepAfterRouteChange);
          return;
        }

        // add toast to array
        this.toasts.push(toast);
        this.TempToast = toast;
        this.ChangeDetectorRef.detectChanges();

        // auto close toast if required
        // if (toast.autoClose)
        // {
        //   setTimeout(() => this.removeAlert(toast), 10000);
        // }
      });

    // clear toasts on location change
    this.routeSubscription = this.router.events.subscribe(event =>
    {
      if (event instanceof NavigationStart)
      {
        this.toastService.clear(this.id);
      }
    });
  }

  removeAlert(toast: Toast)
  {
    // this.toasts = this.toasts.filter(x => x !== toast)
    // this.ChangeDetectorRef.detectChanges();
    // check if already removed to prevent error on auto close
    if (!this.toasts.includes(toast)) return;
    this.toasts = this.toasts.filter(x => x !== toast);
  }

  cssClass(toast: Toast)
  {
    if (!toast) return;

    const classes = [];

    const alertTypeClass = {
      [ToastType.Success]: 'success',
      [ToastType.Error]: 'error',
      [ToastType.Info]: 'info',
      [ToastType.Warning]: 'warning'
    };

    classes.push(alertTypeClass[toast.type]);

    return classes.join(' ');
  }

  ngOnDestroy()
  {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
}
