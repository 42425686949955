<ngb-toast *ngFor="let toast of toasts" [autohide]="toast.autoClose"> 
    <div class="toast " [class]="cssClass(toast)">
        <div class="outer-container">
            <i class="fas fa-{{toast.icon}}"></i>
        </div>
        <div class="inner-container">
            <p>{{ toast.title }}</p>
            <p>{{ toast.message }}</p>
        </div>
        <button (click)="removeAlert(toast)">
            <span>
                <img src="./assets/image/png/close.png" alt="" width="18px" height="18px" class="svg-icon svg-icon-2"/>
            </span></button>
    </div>
</ngb-toast>