import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { Products } from 'src/app/core/constant/product.constants';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';
import { API } from 'src/app/core/constant/api.constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor
{
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        request = request.clone({
            setHeaders: {
                'X-App-id': Products.OneAPI.toString()
            },
        });
        const token = this.authenticationService.user.access_token;
        if (token)
        {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${ token }`
                },
            });
        }
        return next.handle(request);
    }
}
