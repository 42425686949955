import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
// #fake-start#
import { ToastModule } from './shared/toast/toast.module';
import { JwtInterceptor } from './modules/authentication/interceptors/jwt-interceptor';
import { ErrorInterceptor } from './modules/authentication/interceptors/error.interceptor';
import { AuthCallbackComponent } from './shared/auth-callback/auth-callback.component';
import { ValidateCallbackComponent } from './shared/validate-callback/validate-callback.component';
import { AuthLogoutComponent } from './shared/auth-logout/auth-logout.component';
// import { HighchartsChartModule } from 'highcharts-angular';

// #fake-end#

// function appInitializer(authService: AuthService)
// {
//   return () =>
//   {
//     return new Promise((resolve) =>
//     {
//       //@ts-ignore
//       authService.getUserByToken().subscribe().add(resolve);
//     });
//   };
// }

@NgModule({
  declarations: [AppComponent, AuthCallbackComponent, ValidateCallbackComponent, AuthLogoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    // #fake-start#
    // environment.isMockEnabled
    //   ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
    //     passThruUnknownUrl: true,
    //     dataEncapsulation: false,
    //   })
    //   : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    ToastModule,
    // HighchartsChartModule,
  ],
  providers: [
    // TODO: make this wokring
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AuthService],
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
