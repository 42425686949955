import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/authentication/guards/auth-guard';
import { AuthCallbackComponent } from './shared/auth-callback/auth-callback.component';
import { ValidateCallbackComponent } from './shared/validate-callback/validate-callback.component';
import { AuthLogoutComponent } from './shared/auth-logout/auth-logout.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  { path: 'signin-oidc', component: AuthCallbackComponent  },
  { path: 'signout-oidc', component: AuthLogoutComponent  },
  { path: 'validate', component: ValidateCallbackComponent  },
  { path: 'signout-callback-oidc',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./shared/_metronic/layout/layout.module').then((m) => m.LayoutModule), },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./shared/_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
