import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './shared/_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { environment } from 'src/environments/environment';
@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy
{
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private authenticationService : AuthenticationService
  )
  {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit()
  {
    // window.addEventListener('message', (event) => {  
    //   if (event.origin === environment.identityServiceUrl.substring(0, environment.identityServiceUrl.length - 1) && event.data === 'changed') {
    //     this.authenticationService.logout()
    //   }
    // })
    localStorage.setItem('kt_theme_mode_value','light');

  }
  ngOnDestroy() {
  
  }
}
